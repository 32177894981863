"use strict";
import { DcBaseComponent } from "@deleteagency/dc";
import Accordion from "components/accordion/js/accordion";
export default class CallToActionComponent extends DcBaseComponent {
  static getNamespace() {
    return "call-to-action";
  }
  static getRequiredRefs() {
    return ["triggerRef", "contentRef"];
  }
  onInit() {
    this.accordion = new Accordion({
      rootRef: this.element,
      triggerRef: this.refs.triggerRef,
      contentRef: this.refs.contentRef
    });
  }
  destroy() {
    this.accordion.destroy();
  }
}
